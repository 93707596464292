// function _listen(el, event, handler, add = true) {
//   const options = { passive: false };

//   if (add) {
//     el.addEventListener(event, handler, options);
//   } else {
//     el.removeEventListener(event, handler, options);
//   }
// }

function _toggleState($element, stateOne = '1', stateTwo = '0') {
  $element.setAttribute('data-state', $element.getAttribute('data-state') === stateOne ? stateTwo : stateOne);
}

export {
  // _listen,
  _toggleState,
};
