import Zooming from 'zooming';
import { _toggleState } from './utilities';

const domLoad = () => {

  const $header = document.querySelector('#header');
  if ($header) {
    const $menu = $header.querySelector('.main-menu');
    const $mobileMenuToggle = $header.querySelector('#mobile-menu-toggle');
    // Mobile menu
    $mobileMenuToggle.addEventListener('click', (event) => {
      _toggleState($menu, 'closed', 'open');
      event.preventDefault();
    }, false);
  }

  // GeoFence
  const $geoFence = document.querySelector('.geoFence__page');
  if ($geoFence) {
    // ipstack
    // set endpoint and your access key
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      const DONE = 4; // readyState 4 means the request is done.
      const OK = 200; // status 200 is a successful return.
      console.log(request.status);

      if (request.readyState === DONE) {
        if (request.status === OK) {
          // bio.innerHTML = request.responseText;
          const result = JSON.parse(request.responseText);
          console.log(result);

          // česko, slovensko, rakousko, polsko a německo
          if (
            result.country_code === 'CZ'
            || result.country_code === 'SK'
            || result.country_code === 'AT'
            || result.country_code === 'PL'
            || result.country_code === 'DE'
          ) {

            console.log('TRUE');
            $geoFence.classList.remove('geoFence__cz-sk');

          } else {

            console.log('FALSE');
            $geoFence.classList.add('geoFence__cz-sk');
            document.querySelectorAll('.geoFence__item').forEach(($item) => {
              let $video = $item.children[0];
              const $image = document.createElement('img');
              $image.src = `https://img.youtube.com/vi/${$video.dataset.vid}/mqdefault.jpg`;
              $item.prepend($image);
              $video.remove();
              $video = null;
            });

          }

        } else {
          console.log(`An error occurred during your request: ${request.status} ${request.statusText}`);
        }
      }
    };
    const ipstack = {
      accessKey: 'e198ae1dd8c78ad3f778ee8e2a440c27',
      fields: ['country_code'],
    };
    request.open('Get', `http://api.ipstack.com/check?access_key=${ipstack.accessKey}&fields=${ipstack.fields.join(',')}`);
    request.send();

  }

  // Lightbox (zooming)
  const zooming = new Zooming({
    // bgOpacity: 0,
    // customSize: '100%',
    enableGrab: false,
    // preloadImage: true,
    scaleBase: 0.8,
    scrollThreshold: 20,
    // transitionDuration: 0.0001,
    // zIndex: 999999,
  });
  zooming.listen('.zooming');
};

export default domLoad;
